import * as styles from './TopCarouselCoverSkeleton.styles';

export const TopCarouselCoverSkeleton = (): ReactJSX.Element => (
  <section css={styles.wrapperSkeletonStyle}>
    <ul css={styles.listSkeletonStyle}>
      <li css={styles.mainContentSkeletonStyle} />
      <li css={styles.subContentSkeletonStyle} />
      <li css={styles.subContentSkeletonStyle} />
    </ul>
  </section>
);
