import * as styles from './TopCarouselBookSkeleton.styles';

const topCarouselBookSkeletonItems = Array.from({ length: 6 }, (_, index) => index);

export const TopCarouselBookSkeleton = (): ReactJSX.Element => (
  <section css={styles.wrapperStyle}>
    <ul css={styles.listStyle}>
      {topCarouselBookSkeletonItems.map(v => (
        <li key={v} css={styles.itemStyle}>
          <div css={styles.bookSkeletonStyle} />
        </li>
      ))}
    </ul>
  </section>
);
