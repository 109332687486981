import { memo, PropsWithChildren, useMemo } from 'react';

import { Book, BookRenderData } from '@/components/common/Book';
import { BookViewItem } from '@/features/genreHome/views/utils/viewDataUtils';
import { SectionItemBase } from '@/models/backendsApi/v2/Views/ViewsType';

interface GenreHomeBookProps extends PropsWithChildren {
  book: BookRenderData;
  index: number;
}

export const GenreHomeBook = memo(({ book, index, children }: GenreHomeBookProps): ReactJSX.Element => {
  const trackingOptions = useMemo(() => ({ extraParams: { book_index: index } }), [index]);

  return (
    <Book data={book} trackingOptions={trackingOptions}>
      {({ BookFastRenderer }) => <BookFastRenderer index={index}>{children}</BookFastRenderer>}
    </Book>
  );
});

export interface GenreHomeBookItemProps extends PropsWithChildren {
  item: BookViewItem<SectionItemBase>;
  index: number;
}

export const GenreHomeBookItemRenderer = ({ item, index, children }: GenreHomeBookItemProps): ReactJSX.Element => (
  <GenreHomeBook book={item.contents.book} index={index}>
    {children}
  </GenreHomeBook>
);
