import { useEffect, useRef } from 'react';

import { Navigation } from '@/models/backendsApi/v2/Navigation/NavigationType';

export const useNavigationScrollIntoView = (
  selectedId: number | string,
  items: Navigation[],
  isManuallySelected: boolean,
) => {
  const itemRefs = useRef<{ [key: number | string]: HTMLLIElement | null }>({});
  const prevSelectedId = useRef<number | string>(selectedId); // 이전 selectedId 저장

  useEffect(() => {
    const selectedElement = itemRefs.current[selectedId];

    // 첫 랜딩 시 복원
    if (!isManuallySelected && selectedElement) {
      const rect = selectedElement.getBoundingClientRect();
      const isInView = rect.top < window.innerHeight && rect.bottom > 0;
      if (isInView) {
        selectedElement.scrollIntoView({
          behavior: 'instant',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
    // 이전 selectedId와 현재 selectedId가 같다면 스크롤을 복원하지 않음
    if (prevSelectedId.current === selectedId) {
      return;
    }

    if (isManuallySelected && selectedElement) {
      selectedElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });

      // 스크롤 동작 후 이전 selectedId를 현재 selectedId로 업데이트
      prevSelectedId.current = selectedId;
    }
  }, [isManuallySelected, selectedId, items]);

  return (id: string | number) => (el: HTMLLIElement | null) => {
    itemRefs.current[id] = el;
  };
};
