import { GridOrderedBookSkeleton } from '../GridOrderedBook';
import { SectionLayoutSkeleton } from '../SectionLayout';
import * as styles from './GridOrderedLayoutSectionSkeleton.styles';

const defaultArray = Array.from({ length: 9 }, (_, index) => index);

export const GridOrderedLayoutSectionSkeleton = (): ReactJSX.Element => (
  <SectionLayoutSkeleton>
    <div css={styles.containerSkeletonStyle}>
      {defaultArray.map(value => (
        <GridOrderedBookSkeleton key={value} />
      ))}
    </div>
  </SectionLayoutSkeleton>
);

export const GridOrderedLayoutSectionContentSkeleton = (): ReactJSX.Element => (
  <div css={styles.withoutLayoutStyle}>
    <div css={styles.containerSkeletonStyle}>
      {defaultArray.map(value => (
        <GridOrderedBookSkeleton key={value} />
      ))}
    </div>
  </div>
);
