import { SerializedStyles } from '@emotion/react';
import {
  forwardRef,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ArrowButton } from '@/components/common/ArrowButton';
import type { RIDITheme } from '@/components/styles/themes';
import { useLatestRef } from '@/hooks/useLatestRef';
import { EventParamsType, sendClickEvent, sendScrollEvent } from '@/utils/eventClient';
import { debounce } from '@/utils/functions';

import { defaultBehavior } from './behaviors';
import * as styles from './HorizontalScrollContainer.styles';

export interface HorizontalScrollContainerEventParams {
  params?: EventParamsType;
  screenName: string;
}

export interface HorizontalScrollContainerScrollContext {
  container: HTMLDivElement;
  isOnStart: boolean;
  isOnEnd: boolean;
}

export type HorizontalScrollContainerScrollBehavior = (
  context: HorizontalScrollContainerScrollContext,
  multiplier: number,
) => void;

export interface HorizontalScrollContainerController {
  focus(element: HTMLElement): void;
  getScroller(): HTMLElement | null;
}

export interface HorizontalScrollContainerProps {
  className?: string;
  children?: ReactNode;
  scrollBehavior?: HorizontalScrollContainerScrollBehavior;
  leftArrowLabel?: string;
  rightArrowLabel?: string;
  arrowCss?: SerializedStyles | ((theme: RIDITheme) => SerializedStyles);
  arrowContainerCss?: SerializedStyles;
  contentCss?: SerializedStyles;
  contentContainerCss?: SerializedStyles;
  eventParams?: HorizontalScrollContainerEventParams;
}

export const HorizontalScrollContainer = forwardRef<
  HorizontalScrollContainerController,
  HorizontalScrollContainerProps
>(
  (
    {
      className,
      children,
      scrollBehavior = defaultBehavior,
      leftArrowLabel = '이전',
      rightArrowLabel = '다음',
      arrowCss,
      arrowContainerCss,
      contentCss,
      contentContainerCss,
      eventParams,
    },
    controllerRef,
  ): ReactJSX.Element => {
    /*
     * States
     */
    const [isOnStart, setIsOnStart] = useState(true);
    const [isOnEnd, setIsOnEnd] = useState(true);

    /*
     * Refs
     */
    const nodeRef = useRef<HTMLDivElement>(null);
    const startMarkerRef = useRef<HTMLDivElement>(null);
    const endMarkerRef = useRef<HTMLDivElement>(null);
    const clickRef = useRef<boolean>(false);

    /*
     * IntersectionObserver & Marker
     */
    useEffect(() => {
      const startMarker = startMarkerRef.current;
      const endMarker = endMarkerRef.current;
      const node = nodeRef.current;

      if (!startMarker || !endMarker || !node) {
        return undefined;
      }

      const intersectionObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.rootBounds?.width === 0) {
              // When the container is hidden
              return;
            }

            const visible = entry.isIntersecting || entry.intersectionRatio > 0;
            if (entry.target === startMarkerRef.current) {
              setIsOnStart(visible);
            } else if (entry.target === endMarkerRef.current) {
              setIsOnEnd(visible);
            }
          });
        },
        { root: node },
      );

      intersectionObserver.observe(startMarker);
      intersectionObserver.observe(endMarker);

      return () => {
        intersectionObserver.unobserve(startMarker);
        intersectionObserver.unobserve(endMarker);
      };
    }, []);

    /*
     * Imperative Focus Handler
     */
    const focusElement = (element: HTMLElement) => {
      const node = nodeRef.current;
      if (!node) {
        return;
      }

      const { offsetLeft = 0, clientWidth = 0 } = element;
      node.scrollTo({
        top: 0,
        left: offsetLeft - (node.clientWidth - clientWidth) / 2,
        behavior: 'smooth',
      });
    };

    useImperativeHandle(controllerRef, () => ({
      focus: focusElement,
      getScroller: () => nodeRef.current,
    }));

    /*
     * Scroll Behaviour
     */
    const scrollDirection = useCallback(
      (direction: number) => (event: MouseEvent) => {
        const node = nodeRef.current;
        if (!node) {
          return;
        }

        if (eventParams) {
          clickRef.current = true;
          sendClickEvent(eventParams.screenName, direction === -1 ? 'scroll_left' : 'scroll_right', eventParams.params);
        }

        event.stopPropagation();
        event.preventDefault();

        scrollBehavior(
          {
            container: node,
            isOnStart,
            isOnEnd,
          },
          direction,
        );
      },
      [scrollBehavior, isOnStart, isOnEnd, eventParams],
    );

    const scrollLeft = useMemo(() => scrollDirection(-1), [scrollDirection]);
    const scrollRight = useMemo(() => scrollDirection(1), [scrollDirection]);

    /*
     * Event Handling
     */
    const eventParamsRef = useLatestRef(eventParams);
    const onScroll = useMemo(
      () =>
        debounce(() => {
          if (clickRef.current) {
            clickRef.current = false;
            return;
          }

          if (eventParamsRef.current) {
            sendScrollEvent(eventParamsRef.current.screenName, 'section', eventParamsRef.current.params);
          }
        }, 500),
      [eventParamsRef],
    );

    /*
     * Render
     */
    return (
      <div css={styles.scrollContainerWrapperStyle} className={className}>
        <div
          css={[styles.slidingWrapperStyle, contentContainerCss]}
          ref={nodeRef}
          data-testid="HorizontalScrollContainer__scroller"
          onScroll={onScroll}>
          <div
            css={[styles.markerStyle, styles.markerStartStyle]}
            ref={startMarkerRef}
            data-testid="HorizontalScrollContainer__marker--start"
          />
          <div css={[styles.contentStyle, contentCss]}>{children}</div>
          <div
            css={[styles.markerStyle, styles.markerEndStyle]}
            ref={endMarkerRef}
            data-testid="HorizontalScrollContainer__marker--end"
          />
        </div>

        <div css={[styles.sliderWrapperStyle, arrowContainerCss]}>
          <ArrowButton
            css={arrowCss}
            direction="left"
            label={leftArrowLabel}
            disabled={isOnStart}
            onClick={scrollLeft}
          />
          <ArrowButton
            css={arrowCss}
            direction="right"
            label={rightArrowLabel}
            disabled={isOnEnd}
            onClick={scrollRight}
          />
        </div>
      </div>
    );
  },
);
