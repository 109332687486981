import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const listStyle = css`
  display: flex;
  flex-wrap: wrap;
`;

export const listItemStyle = css`
  width: 132px;
  height: 34px;

  margin-bottom: 6px;

  &:first-of-type,
  &:nth-of-type(3),
  &:nth-of-type(4) {
    width: 66px;
  }

  &:not(:last-of-type) {
    margin-right: 6px;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 131px;
      height: 32px;

      &:first-of-type,
      &:nth-of-type(3),
      &:nth-of-type(4) {
        width: 62px;
      }
    `,
  )}
`;

export const itemSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 100%;
  height: 100%;

  border-radius: 4px;
  background-color: ${theme.colors.grey100};
`;
