import { css, SerializedStyles } from '@emotion/react';

import { pageContainerStyle } from '@/components/styles';
import { BreakPoint, orBelow, touchDevice } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const sectionStyle = pageContainerStyle;

export const sectionSkeletonBaseStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.grey100};
  border-radius: 4px;
`;

export const horizontalSectionStyle = css`
  ${sectionStyle};
  ${touchDevice(css`
    padding-left: 0;
    padding-right: 0;
  `)}
`;

export const horizontalSectionScrollContainerStyle = touchDevice(css`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`);

export const horizontalSectionScrollContainerListStyle = touchDevice(css`
  padding-left: calc((100vw - 1110px) / 2);
  padding-right: calc((100vw - 1110px) / 2);

  ${orBelow(
    BreakPoint.DesktopMedium,
    css`
      padding-left: 30px;
      padding-right: 30px;
    `,
  )}

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-left: 16px;
      padding-right: 16px;
    `,
  )};
`);

export const horizontalSectionArrowContainerStyle = css`
  width: calc(100% + 40px);
  padding: 0;
  margin: 0 -20px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: calc(100% + 32px);
      margin: 0 -16px;
    `,
  )};
`;

export const sectionMarginStyle = css`
  margin-top: 40px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 26px;
    `,
  )};

  &:first-of-type {
    margin-top: 0;
  }
`;
