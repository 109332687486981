import { LegacyRef, useContext } from 'react';

import { Author } from '@/base/interfaces/Author';
import {
  BookDataContext,
  BookDefinitionContext,
  BookPreset,
  RenewalBookAuthors,
  RenewalBookCoverSizePreset,
  RenewalBookMetadataSizePreset,
  RenewalBookPreset,
  RenewalBookPresetComponents,
  RenewalBookRendererSizePreset,
  RenewalBookSizeTypes,
} from '@/components/common/Book';
import { BreakPoint } from '@/components/styles/media';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';
import { sortAuthors } from '@/utils/author';
import { modularComponent, ModularComponentProps } from '@/utils/modularComponent';

import { GenreHomeBook, GenreHomeBookItemRenderer } from '../GenreHomeBook';
import * as styles from './GridOrderedBook.styles';

const DEFAULT_UNIT = '화';
const COLLAPSE = [{ value: false }];

function assertNumber(v?: number, message?: string): asserts v is number {
  if (typeof v !== 'number') {
    throw new Error(`Assertion failed: ${message || 'value is not a number'}`);
  }
}

// BookRenderer Definition
export const GridOrderedBookRenderer = modularComponent(() => ({ index }) => {
  const { BookCoverRenderer, BookMetadataRenderer, BookTouchArea, BookViewTracker } =
    useContext(BookDefinitionContext).components;

  assertNumber(index, 'GridOrderedBookRenderer index is not number');

  return (
    <BookViewTracker>
      <div css={styles.containerStyle}>
        <div css={styles.thumbnailContainerStyle}>
          <BookCoverRenderer index={index} />
        </div>
        <div css={styles.rankingContainerStyle}>
          <div css={styles.rankingStyle}>{index + 1}</div>
        </div>
        <div css={styles.metadataContainerStyle}>
          <BookMetadataRenderer index={index} />
        </div>
        <BookTouchArea />
      </div>
    </BookViewTracker>
  );
});

export const GridOrderedBookAuthors = modularComponent(options => {
  const AllAuthors = RenewalBookAuthors.withOptions({
    ...options,
    clipCount: 1,
    clipBreakPoint: 1,
  });

  const ClippedAuthors = RenewalBookAuthors.withOptions({
    ...options,
    clipCount: null,
    clipBreakPoint: Number.POSITIVE_INFINITY,
  });

  return props => {
    const bookData = useContext(BookDataContext);
    const hasFreeBadge = !!bookData.cover?.freeBook;
    const authors = bookData.metadata?.authors;
    const authorNameLength = sortAuthors(authors as Author[]).find(author => author?.role !== 'translator')?.name
      ?.length;

    const isMobile = useResponsiveIsBelow(BreakPoint.DesktopSmall);

    if (!authorNameLength) {
      return <></>;
    }

    let showClipped = false;
    if (hasFreeBadge) {
      showClipped = isMobile ? authorNameLength >= 10 : authorNameLength >= 7;
    } else {
      showClipped = isMobile ? authorNameLength >= 15 : authorNameLength >= 14;
    }

    return showClipped ? <ClippedAuthors {...props} /> : <AllAuthors {...props} />;
  };
}, RenewalBookAuthors.getOptions());

export const GridOrderedBookMetadataItemRenderer = modularComponent(() => () => {
  const { BookAuthors, BookStarRate, BookMetadataItemGroup, BookDefaultMetadataItem } =
    useContext(BookDefinitionContext).components;
  const { cover } = useContext(BookDataContext);
  const freeCount = cover?.freeBook?.count ?? 0;

  return (
    <>
      <BookMetadataItemGroup collapse={COLLAPSE} css={styles.authorFreebookMarginStyle}>
        <BookAuthors css={styles.authorStyle} />
        {freeCount > 0 && (
          <BookDefaultMetadataItem css={styles.freebookStyle}>
            <span css={styles.freebookUnitFontSizeStyle}>
              {freeCount}
              {cover?.freeBook?.unit ?? DEFAULT_UNIT} 무료
            </span>
          </BookDefaultMetadataItem>
        )}
      </BookMetadataItemGroup>
      <BookMetadataItemGroup css={styles.ratingMarginStyle}>
        <BookStarRate css={styles.ratingStyle} />
      </BookMetadataItemGroup>
    </>
  );
});

export const GridOrderedBookSkeleton = modularComponent(
  () =>
    ({ elementRef }: { elementRef?: LegacyRef<HTMLDivElement> } & ModularComponentProps): ReactJSX.Element => (
      <div css={styles.bookContainerSkeletonStyle} ref={elementRef}>
        <div css={styles.bookThumbnailSkeletonStyle} />
        <div css={styles.metaContainerSkeletonStyle}>
          <div css={styles.metaBookTitleSkeletonStyle} />
          <div css={styles.metaBookAuthorSkeletonStyle} />
          <div css={styles.metaBookRateSkeletonStyle} />
        </div>
      </div>
    ),
);

const GridOrderedBookBasePreset: BookPreset = components => {
  const presetComponents = { ...components } as RenewalBookPresetComponents;
  const BookAuthor = GridOrderedBookAuthors.withOptions(presetComponents.BookAuthors.getOptions()).addResponsiveOption(
    'textSize',
    {
      value: 13,
      orBelow: BreakPoint.DesktopSmall,
    },
  );

  presetComponents.BookAuthors = BookAuthor;
  presetComponents.BookBadgeRenderer = presetComponents.BookNothing;

  const BookTitle = presetComponents.BookTitle.withOptions({ lineCount: 1 }).addResponsiveOption('textSize', {
    value: 15,
    orBelow: BreakPoint.DesktopSmall,
  });

  presetComponents.BookTitle = BookTitle;

  presetComponents.BookAdultBadge = presetComponents.BookAdultBadge.withOptions({
    iconSize: [{ value: 20 }, { value: 16, orBelow: BreakPoint.DesktopSmall }],
    position: [{ value: 4 }, { value: 3, orBelow: BreakPoint.DesktopSmall }],
  });

  presetComponents.BookSkeleton = GridOrderedBookSkeleton;
  presetComponents.BookMetadataItems = GridOrderedBookMetadataItemRenderer;
  presetComponents.BookRenderer = GridOrderedBookRenderer;

  return presetComponents;
};

// Preset Definition
export const GridOrderedBookPreset = [
  RenewalBookPreset,
  RenewalBookCoverSizePreset([
    { orBelow: BreakPoint.DesktopSmall, value: RenewalBookSizeTypes.EXTRA_SMALL },
    { greaterThan: BreakPoint.DesktopSmall, value: RenewalBookSizeTypes.SMALL },
  ]),
  RenewalBookMetadataSizePreset([
    { orBelow: BreakPoint.DesktopSmall, value: RenewalBookSizeTypes.EXTRA_SMALL },
    { greaterThan: BreakPoint.DesktopSmall, value: RenewalBookSizeTypes.LARGE },
  ]),
  RenewalBookRendererSizePreset([
    {
      orBelow: BreakPoint.DesktopSmall,
      value: { width: styles.THUMBNAIL_MOBILE_WIDTH, height: styles.THUMBNAIL_MOBILE_HEIGHT },
    },
    {
      greaterThan: BreakPoint.DesktopSmall,
      value: { width: styles.THUMBNAIL_DESKTOP_WIDTH, height: styles.THUMBNAIL_DESKTOP_HEIGHT },
    },
  ]),
  GridOrderedBookBasePreset,
];

export const GridOrderedBook = GenreHomeBook;
export const GridOrderedBookItemRenderer = GenreHomeBookItemRenderer;

export const __tests__ = { assertNumber };
