import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const wrapperStyle = css`
  display: flex;
  justify-content: center;
  margin: 30px 0 40px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      justify-content: flex-start;
      padding-left: 16px;
      margin: 16px 0 24px;
    `,
  )}
`;

export const itemWrapperStyle = css`
  &:not(:first-of-type) {
    margin-left: 20px;

    ${orBelow(
      BreakPoint.DesktopSmall,
      css`
        margin-left: 10px;
      `,
    )}
  }
`;

export const iconSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 70px;
  height: 70px;

  border-radius: 100%;
  background-color: ${theme.colors.grey100};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 56px;
      height: 56px;
    `,
  )}
`;

export const labelSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 60px;
  height: 16px;
  margin: 8px auto 0;

  border-radius: 4px;
  background-color: ${theme.colors.grey100};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 46px;
      height: 13px;
    `,
  )}
`;
