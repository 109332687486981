import { ReactNode } from 'react';

import * as styles from './SectionLayoutSkeleton.styles';

interface SectionLayoutSkeletonProps {
  children: ReactNode;
  className?: string;
}

export const SectionLayoutSkeleton = ({ children, className }: SectionLayoutSkeletonProps): ReactJSX.Element => (
  <section className={className} css={styles.sectionLayoutSkeletonContainerStyle}>
    <header css={styles.sectionHeaderSkeletonStyle} />
    {children}
  </section>
);
