import { css } from '@emotion/react';

import { pageContainerStyle } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';

export const withoutLayoutStyle = css`
  ${pageContainerStyle};
  padding: 0;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 16px;
    `,
  )}
`;

export const containerSkeletonStyle = css`
  width: 1170px;
  display: flex;
  flex-wrap: wrap;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 981px;
    `,
  )}
`;
