import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow, touchDevice } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export { sectionStyle } from '../../sections/index.styles';
export { sectionMarginStyle } from '../../sections/index.styles';

export const extraPaddingBottomStyle = css`
  padding-bottom: 30px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-bottom: 16px;
    `,
  )}
`;

export const sectionHeaderStyle = css`
  height: 48px;
  padding-top: 6px;
  padding-bottom: 16px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 36px;
      padding-top: 5px;
      padding-bottom: 11px;
    `,
  )};
`;

export const sectionHeadContentStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const sectionTitleWrapperStyle = css`
  display: flex;
  align-items: center;
  min-width: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -2px;
`;

export const sectionTitleStyle = (theme: RIDITheme): SerializedStyles => css`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: ${theme.colors.grey600};
  min-width: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 18px;
      line-height: 20px;
    `,
  )}
`;

export const sectionHeadExtraStyle = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const sectionHeadExtraDividerStyle = (theme: RIDITheme): SerializedStyles => css`
  margin: 0px 16px;
  width: 1px;
  height: 16px;
  background-color: ${theme.colors.grey100};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin: 0px 11px;
    `,
  )}
`;

export const sectionMoreLinkStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.grey500};
  letter-spacing: -0.01em;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 14px;
      line-height: 16px;
      margin-top: -1px;
    `,
  )}
`;

export { horizontalSectionStyle } from '../../sections/index.styles';
export const horizontalSectionHeaderStyle = touchDevice(css`
  padding-left: 30px;
  padding-right: 30px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-left: 16px;
      padding-right: 16px;
    `,
  )};
`);
