import { SectionLayoutSkeleton } from '../../common/SectionLayout';
import * as styles from './OnePickSkeleton.styles';

const onePickSectionSkeletonItems = Array.from({ length: 6 }, (_, index) => index);

export const OnePickSkeleton = () => (
  <SectionLayoutSkeleton css={styles.onePickSectionSkeletonStyle}>
    <div css={styles.onePickSectionItemSkeletonWrapperStyle}>
      {onePickSectionSkeletonItems.map(value => (
        <div
          key={value}
          css={styles.onePickSectionItemSkeletonStyle}
          role="alert"
          aria-live="polite"
          aria-busy="true"
          aria-label="섹션 불러오는 중"
        />
      ))}
    </div>
  </SectionLayoutSkeleton>
);
