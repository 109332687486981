import { createContext, ReactNode, useContext, useMemo } from 'react';

import { usePageContext } from '@/components/common/PageContext';
import { ViewSectionFrameBase } from '@/features/genreHome/views/utils/viewDataUtils';
import { ViewSectionItemsExtended } from '@/features/genreHome/views/viewsSlice';
import { getRDTGenrePrefix, RDTTrackingURIParams } from '@/utils/query';

export type SectionTrackingDataParams = {
  section_id: number;
  section_title: string;
  section_layout: string;
  section_type: string | undefined;
  section_landing_url: string | undefined;
  section_index: number;
};

export type SectionTrackingDataContextType = {
  screenName: string;
  params: SectionTrackingDataParams;
  bookDefinition: {
    screenName: string;
    params: SectionTrackingDataParams;
    rdtTrackingURIParams: RDTTrackingURIParams;
  };
};

type SectionTrackingDataContextProviderProps = {
  section: ViewSectionFrameBase;
  sectionIndex: number;
  items: ViewSectionItemsExtended<unknown>;
  children: ReactNode;
};

export const SectionTrackingDataContext = createContext<SectionTrackingDataContextType>({
  screenName: '',
  params: {
    section_id: -1,
    section_title: '',
    section_landing_url: undefined,
    section_layout: '',
    section_type: undefined,
    section_index: -1,
  },
  bookDefinition: {
    screenName: '',
    params: {
      section_id: -1,
      section_title: '',
      section_landing_url: undefined,
      section_layout: '',
      section_type: undefined,
      section_index: -1,
    },
    rdtTrackingURIParams: {
      sectionId: '',
    },
  },
});

export const SectionTrackingDataContextProvider = ({
  section,
  sectionIndex,
  children,
  items,
}: SectionTrackingDataContextProviderProps): ReactJSX.Element => {
  const { screenName, params: pageParams } = usePageContext();

  const params = useMemo<SectionTrackingDataParams>(
    () => ({
      ...pageParams,
      section_id: section.id,
      section_title: section.title,
      section_layout: section.layout,
      section_type: section.type,
      section_landing_url: section.more_details?.path || undefined,
      section_index: sectionIndex,
      view_session_id: items.viewSessionId,
    }),
    [
      pageParams,
      section.id,
      section.title,
      section.layout,
      section.type,
      section.more_details?.path,
      sectionIndex,
      items.viewSessionId,
    ],
  );

  // 데이터 분석 시 rdt_sid에 어떤 장르홈에서 이동했는지 referrer의 개념으로 추가 (https://app.asana.com/0/1201635044633282/1202079929549944/f)
  const sectionIdPrefix = getRDTGenrePrefix();
  const snakeCaseLayout = section.layout.replace(
    /(^|[a-z])([A-Z])/g,
    (_match, lowerChar: string, upperChar: string) => (lowerChar ? `${lowerChar}_` : '') + upperChar.toLowerCase(),
  );

  const contextValue = useMemo<SectionTrackingDataContextType>(
    () => ({
      screenName,
      params,
      bookDefinition: {
        screenName,
        params,
        rdtTrackingURIParams: {
          sectionId: `${sectionIdPrefix}${snakeCaseLayout}`,
        },
      },
    }),
    [screenName, params, snakeCaseLayout, sectionIdPrefix],
  );

  return <SectionTrackingDataContext.Provider value={contextValue}>{children}</SectionTrackingDataContext.Provider>;
};

export const useSectionTrackingDataContext = (): SectionTrackingDataContextType => {
  const sectionTrackingDataContext = useContext(SectionTrackingDataContext);
  return sectionTrackingDataContext;
};
