import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const wrapperStyle = css`
  display: flex;
  max-width: 1170px;

  padding: 0 30px;
  margin: 0 auto 30px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 16px;
    `,
  )}
`;

export const bannerSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 552px;
  height: 330px;

  background-color: ${theme.colors.grey100};
  border-radius: 4px;

  &:first-of-type {
    margin-right: 6px;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      &:not(:first-of-type) {
        display: none;
      }

      width: 343px;
      height: 200px;
    `,
  )}
`;
