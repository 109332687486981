import { css } from '@emotion/react';

import {
  BookPreset,
  RenewalBookInfoLevelPreset,
  RenewalBookPreset,
  RenewalBookSizePreset,
} from '@/components/common/Book';
import { BreakPoint, orBelow } from '@/components/styles/media';

import * as constants from './constants';

export const GenreHomeBasePreset = [
  RenewalBookPreset,
  RenewalBookSizePreset([
    {
      greaterThan: BreakPoint.DesktopSmall,
      value: {
        width: constants.DESKTOP_BOOK_DEFAULT_COVER_WIDTH,
        height: constants.DESKTOP_BOOK_DEFAULT_COVER_HEIGHT,
        type: 700,
      },
    },
    {
      orBelow: BreakPoint.DesktopSmall,
      value: {
        width: constants.MOBILE_BOOK_DEFAULT_COVER_WIDTH,
        height: constants.MOBILE_BOOK_DEFAULT_COVER_HEIGHT,
        type: 400,
      },
    },
  ]),
];

export const GenreHome2InfoBasePreset: BookPreset[] = [
  RenewalBookInfoLevelPreset(2),
  (components => ({
    ...components,
    BookFastRenderer: components.BookFastRenderer.addStyle(css`
      width: ${constants.DESKTOP_BOOK_2INFO_BOOK_WIDTH}px;
      height: ${constants.DESKTOP_BOOK_2INFO_BOOK_HEIGHT}px;

      ${orBelow(
        BreakPoint.DesktopSmall,
        css`
          width: ${constants.MOBILE_BOOK_2INFO_BOOK_WIDTH}px;
          height: ${constants.MOBILE_BOOK_2INFO_BOOK_HEIGHT}px;
        `,
      )};
    `),
  })) as BookPreset,
];

export const GenreHome3InfoBasePreset: BookPreset[] = [
  RenewalBookInfoLevelPreset(3),
  (components => ({
    ...components,
    BookFastRenderer: components.BookFastRenderer.addStyle(css`
      width: ${constants.DESKTOP_BOOK_3INFO_BOOK_WIDTH}px;
      height: ${constants.DESKTOP_BOOK_3INFO_BOOK_HEIGHT}px;

      ${orBelow(
        BreakPoint.DesktopSmall,
        css`
          width: ${constants.MOBILE_BOOK_3INFO_BOOK_WIDTH}px;
          height: ${constants.MOBILE_BOOK_3INFO_BOOK_HEIGHT}px;
        `,
      )};
    `),
  })) as BookPreset,
];

export const GenreHome2InfoPreset = [...GenreHomeBasePreset, ...GenreHome2InfoBasePreset];
export const GenreHome3InfoPreset = [...GenreHomeBasePreset, ...GenreHome3InfoBasePreset];
