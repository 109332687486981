import dynamic from 'next/dynamic';
import { ComponentType, Fragment, memo, MutableRefObject, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { GridOrderedLayoutSectionSkeleton } from '@/components/genreHome/common/GridOrderedLayoutSection/GridOrderedLayoutSectionSkeleton';
import {
  ListLayoutSection2InfoSkeleton,
  ListLayoutSection3InfoSkeleton,
} from '@/components/genreHome/common/ListLayoutSection/ListLayoutSectionSkeleton';
import { ListLayoutWithColorBoxSkeleton } from '@/components/genreHome/common/ListLayoutWithColorBox/ListLayoutWithColorBoxSkeleton';
import { EventSkeleton } from '@/components/genreHome/sections/Event/EventSkeleton';
import { KeywordSkeleton } from '@/components/genreHome/sections/Keyword/KeywordSkeleton';
import { QuickMenuSkeleton } from '@/components/genreHome/sections/QuickMenu/QuickMenuSkeleton';
import { SelectionCoverSkeleton } from '@/components/genreHome/sections/SelectionCover/SelectionCoverSkeleton';
import { TopCarouselBannerSkeleton } from '@/components/genreHome/sections/TopCarouselBanner/TopCarouselBannerSkeleton';
import { TopCarouselBookSkeleton } from '@/components/genreHome/sections/TopCarouselBook/TopCarouselBookSkeleton';
import { TopCarouselCoverSkeleton } from '@/components/genreHome/sections/TopCarouselCover/TopCarouselCoverSkeleton';
import { SectionByLayout, SectionMerged, ViewSectionFrameBase } from '@/features/genreHome/views/utils/viewDataUtils';
import {
  fetchSectionItemsResourceAction,
  refetchSectionItemsAction,
  removeSectionItemsAction,
  sectionContentsByIdSelector,
  sectionItemsByIdSelector,
  ViewSectionItemsExtended,
} from '@/features/genreHome/views/viewsSlice';
import { isHydrateNeededSelector } from '@/features/global/isHydrateNeeded';
import { RootState } from '@/features/store';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useLoggedUser } from '@/hooks/useLoggedUser';
import { SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { OnePickSkeleton } from '../../sections/OnePick/OnePickSkeleton';
import { SectionTrackingDataContextProvider } from '../SectionTrackingDataContextProvider';

/*
 * Types
 * ====
 */
type SectionComponentTypeByLayout<L extends SectionLayoutType> = (props: {
  section: SectionByLayout<L>;
  sectionIndex: number;
}) => ReactJSX.Element;

type SectionRendererType = ComponentType<SectionRendererProps>;
type SectionRendererProps = {
  sectionSignal: Signal<SectionMerged>;
  sectionIndex: number;
  itemsSignal: Signal<ViewSectionItemsExtended<unknown>>;
  fallback: ReactJSX.Element;
};

type SectionRendererTypeByLayout<L extends SectionLayoutType> = (props: {
  sectionSignal: Signal<SectionByLayout<L>>;
  sectionIndex: number;
  itemsSignal: Signal<ViewSectionItemsExtended<SectionByLayout<L>['items']>>;
  fallback: ReactJSX.Element;
}) => ReactJSX.Element;

/*
 * Signals
 * ====
 * See Also: `SuspendedSection`
 */
type Signal<T> = MutableRefObject<SignalValue<T>>;
type SignalValue<T> = { initialValue: T; bindUpdate: (callback: (value: T) => void) => () => void };
const useSignal = <T,>(value: T): Signal<T> => {
  const [updates] = useState(() => new Set<(value: T) => void>());

  const valueRef = useRef(value);
  const ref = useRef<SignalValue<T>>({
    initialValue: value,
    bindUpdate(callback) {
      callback(valueRef.current);

      updates.add(callback);
      return () => updates.delete(callback);
    },
  });

  useEffect(() => {
    valueRef.current = value;

    if (updates.size !== 0) {
      updates.forEach(update => update(value));
    }
  }, [updates, value]);

  return ref;
};

const useSubscribeSignal = <T,>(signal: Signal<T>): T => {
  const [value, setValue] = useState(signal.current.initialValue);

  useEffect(() => {
    const currentSignal = signal.current;
    return currentSignal.bindUpdate(setValue);
  }, [signal]);

  return value;
};

/*
 *  Renderer
 * ====
 */
const withRenderer =
  <L extends SectionLayoutType>(SectionComponent: SectionComponentTypeByLayout<L>): SectionRendererTypeByLayout<L> =>
  ({ sectionSignal, sectionIndex, itemsSignal, fallback }) => {
    const section = useSubscribeSignal(sectionSignal);
    const items = useSubscribeSignal(itemsSignal);
    const rendered = useMemo(() => {
      if (items.defaultLoaderSpec && (items.hasItemsToFetch || items.isFetching)) {
        return fallback;
      }

      if (section.items === null) {
        return <></>;
      }

      return <SectionComponent section={section} sectionIndex={sectionIndex} />;
    }, [section, sectionIndex, fallback, items.defaultLoaderSpec, items.hasItemsToFetch, items.isFetching]);

    return rendered;
  };

/*
 * Sections
 * ====
 */
const AiRecommendationRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/AiRecommendation')
    .then(mod => mod.AiRecommendation)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const BestSellerRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/BestSeller')
    .then(mod => mod.BestSeller)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const EventHorizontalRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/Event/EventHorizontal')
    .then(mod => mod.EventHorizontal)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const EventVerticalRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/Event/EventVertical')
    .then(mod => mod.EventVertical as SectionComponentTypeByLayout<SectionLayoutType.EventVertical>)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const GroupRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/Group')
    .then(mod => mod.Group)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const ImageGradientBannerRenderer = dynamic(
  import('@/components/genreHome/sections/ImageGradientBanner')
    .then(mod => mod.ImageGradientBanner)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const KeywordRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/Keyword')
    .then(mod => mod.Keyword)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const NewReleaseRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/NewRelease')
    .then(mod => mod.NewRelease)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const OnePickRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/OnePick')
    .then(mod => mod.OnePick)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const QuickMenuRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/QuickMenu')
    .then(mod => mod.QuickMenu)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const ReadingBookRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/ReadingBook')
    .then(mod => mod.ReadingBook)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const SelectionRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/Selection')
    .then(mod => mod.Selection)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const SelectionCarouselRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/SelectionCarousel')
    .then(mod => mod.SelectionCarousel)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const SelectionCoverRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/SelectionCover')
    .then(mod => mod.SelectionCover)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const SelectionHookingSentenceRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/SelectionHookingSentence')
    .then(mod => mod.SelectionHookingSentence)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const SelectionMultilineRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/SelectionMultiline')
    .then(mod => mod.SelectionMultiline)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const SelectionOriginalRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/SelectionOriginal')
    .then(mod => mod.SelectionOriginal)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const TopCarouselBannerRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/TopCarouselBanner')
    .then(mod => mod.TopCarouselBanner as SectionComponentTypeByLayout<SectionLayoutType.TopCarouselBanner>)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const TopCarouselBookRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/TopCarouselBook')
    .then(mod => mod.TopCarouselBook)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const TopCarouselCoverRenderer = dynamic<SectionRendererProps>(
  import('@/components/genreHome/sections/TopCarouselCover')
    .then(mod => mod.TopCarouselCover as SectionComponentTypeByLayout<SectionLayoutType.TopCarouselCover>)
    .then(withRenderer)
    .then(Component => ({ default: Component as SectionRendererType })),
  { suspense: true },
);

const genreHomeSectionComponentMap: Partial<Record<SectionLayoutType, SectionRendererType>> = {
  [SectionLayoutType.AiRecommendation]: AiRecommendationRenderer,
  [SectionLayoutType.BestSeller]: BestSellerRenderer,
  [SectionLayoutType.EventHorizontal]: EventHorizontalRenderer,
  [SectionLayoutType.EventVertical]: EventVerticalRenderer,
  [SectionLayoutType.Group]: GroupRenderer,
  [SectionLayoutType.ImageGradientBanner]: ImageGradientBannerRenderer,
  [SectionLayoutType.Keyword]: KeywordRenderer,
  [SectionLayoutType.NewRelease]: NewReleaseRenderer,
  [SectionLayoutType.OnePick]: OnePickRenderer,
  [SectionLayoutType.QuickMenu]: QuickMenuRenderer,
  [SectionLayoutType.ReadingBook]: ReadingBookRenderer,
  [SectionLayoutType.Selection]: SelectionRenderer,
  [SectionLayoutType.SelectionCarousel]: SelectionCarouselRenderer,
  [SectionLayoutType.SelectionCover]: SelectionCoverRenderer,
  [SectionLayoutType.SelectionHookingSentence]: SelectionHookingSentenceRenderer,
  [SectionLayoutType.SelectionMultiline]: SelectionMultilineRenderer,
  [SectionLayoutType.SelectionOriginal]: SelectionOriginalRenderer,
  [SectionLayoutType.TopCarouselBanner]: TopCarouselBannerRenderer,
  [SectionLayoutType.TopCarouselBook]: TopCarouselBookRenderer,
  [SectionLayoutType.TopCarouselCover]: TopCarouselCoverRenderer,
};

/*
 * Skeletons
 * ====
 */
const genreHomeSectionSkeletonMap: Partial<Record<SectionLayoutType, () => ReactJSX.Element>> = {
  [SectionLayoutType.AiRecommendation]: ListLayoutSection2InfoSkeleton,
  [SectionLayoutType.BestSeller]: GridOrderedLayoutSectionSkeleton,
  [SectionLayoutType.EventHorizontal]: EventSkeleton,
  [SectionLayoutType.EventVertical]: EventSkeleton,
  [SectionLayoutType.Keyword]: KeywordSkeleton,
  [SectionLayoutType.NewRelease]: ListLayoutSection2InfoSkeleton,
  [SectionLayoutType.OnePick]: OnePickSkeleton,
  [SectionLayoutType.QuickMenu]: QuickMenuSkeleton,
  [SectionLayoutType.ReadingBook]: GridOrderedLayoutSectionSkeleton,
  [SectionLayoutType.Selection]: ListLayoutSection3InfoSkeleton,
  [SectionLayoutType.SelectionCarousel]: ListLayoutWithColorBoxSkeleton,
  [SectionLayoutType.SelectionCover]: SelectionCoverSkeleton,
  [SectionLayoutType.SelectionHookingSentence]: ListLayoutSection2InfoSkeleton,
  [SectionLayoutType.TopCarouselBook]: TopCarouselBookSkeleton,
  [SectionLayoutType.TopCarouselBanner]: TopCarouselBannerSkeleton,
  [SectionLayoutType.TopCarouselCover]: TopCarouselCoverSkeleton,
};

export const GenreHomeSectionPlaceholder = ({
  sectionFrame,
}: {
  sectionFrame: ViewSectionFrameBase;
}): ReactJSX.Element => {
  const SectionSkeleton = genreHomeSectionSkeletonMap[sectionFrame.layout] || Fragment;
  return <SectionSkeleton />;
};

/*
 * Section Rendering
 * ====
 */
const SuspendedSection = memo(
  ({
    layout,
    sectionSignal,
    sectionIndex,
    itemsSignal,
    fallback,
  }: SectionRendererProps & { layout: SectionLayoutType }): ReactJSX.Element => {
    const SectionRenderer = genreHomeSectionComponentMap[layout] || Fragment;

    // <Suspense /> 가 Hydrating이 끝나기 전에 업데이트 되지 않도록 함
    // 이 컴포넌트는 `memo()`를 사용하며, 상위에서는 `useSignal()` 을 사용하여 방어함

    return (
      <Suspense fallback={fallback}>
        <SectionRenderer
          sectionSignal={sectionSignal}
          sectionIndex={sectionIndex}
          itemsSignal={itemsSignal}
          fallback={fallback}
        />
      </Suspense>
    );
  },
);

const useDefaultLoader = (sectionId: number, items: ViewSectionItemsExtended<unknown>) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = !!useLoggedUser();
  const isHydrateNeeded = useSelector(isHydrateNeededSelector);
  const isItemsEmpty = Array.isArray(items.items) && items.items.length === 0;

  useEffect(() => {
    const onPageShow = (pageShowEvent: PageTransitionEvent) => {
      if (!pageShowEvent.persisted || !items.defaultLoaderSpec) {
        return;
      }

      dispatch(refetchSectionItemsAction({ sectionId }));
    };

    window.addEventListener('pageshow', onPageShow);
    return () => window.removeEventListener('pageshow', onPageShow);
  }, [dispatch, items.defaultLoaderSpec, sectionId]);

  useEffect(() => {
    if (!items.hasItemsToFetch || !items.defaultLoaderSpec) {
      if (isItemsEmpty) {
        dispatch(removeSectionItemsAction({ sectionId }));
      }
      return;
    }

    if (items.defaultLoaderSpec.needLogin) {
      if (isLoggedIn) {
        dispatch(
          fetchSectionItemsResourceAction({
            reqParams: { sectionId, endPoint: items.defaultLoaderSpec.resourceUrl },
          }),
        );
      } else if (!isHydrateNeeded && !isLoggedIn) {
        dispatch(removeSectionItemsAction({ sectionId }));
      }
      return;
    }

    dispatch(
      fetchSectionItemsResourceAction({
        reqParams: { sectionId, endPoint: items.defaultLoaderSpec.resourceUrl },
      }),
    );
  }, [dispatch, isLoggedIn, isItemsEmpty, isHydrateNeeded, sectionId, items.hasItemsToFetch, items.defaultLoaderSpec]);
};

interface GenreHomeSectionProps {
  sectionFrame: ViewSectionFrameBase;
  sectionIndex: number;
}

export const GenreHomeSection = memo(({ sectionFrame, sectionIndex }: GenreHomeSectionProps): ReactJSX.Element => {
  const fallback = useMemo(() => <GenreHomeSectionPlaceholder sectionFrame={sectionFrame} />, [sectionFrame]);
  const items = useSelector((state: RootState) => sectionItemsByIdSelector(state, sectionFrame.id));
  const contents = useSelector((state: RootState) => sectionContentsByIdSelector(state, sectionFrame.id));
  const section = useMemo(
    () => ({ ...sectionFrame, contents, items: items.items }),
    [sectionFrame, items.items, contents],
  );

  const sectionSignal = useSignal(section);
  const itemsSignal = useSignal(items);

  useDefaultLoader(sectionFrame.id, items);

  return (
    <SectionTrackingDataContextProvider section={section} sectionIndex={sectionIndex} items={items}>
      <SuspendedSection
        layout={section.layout}
        sectionSignal={sectionSignal}
        sectionIndex={sectionIndex}
        itemsSignal={itemsSignal}
        fallback={fallback}
      />
    </SectionTrackingDataContextProvider>
  );
});
