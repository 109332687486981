import { SectionLayoutSkeleton } from '../../common/SectionLayout';
import * as styles from './KeywordSkeleton.styles';

const keywordSkeletonItems = Array.from({ length: 5 }, (_, index) => index);

export const KeywordSkeleton = (): ReactJSX.Element => (
  <SectionLayoutSkeleton>
    <ul css={styles.listStyle}>
      {keywordSkeletonItems.map(v => (
        <li key={v} css={styles.listItemStyle}>
          <div css={styles.itemSkeletonStyle} />
        </li>
      ))}
    </ul>
  </SectionLayoutSkeleton>
);
