import type { SerializedStyles } from '@emotion/react';
import { memo, ReactNode } from 'react';

import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';

import { useSectionTrackingDataContext } from '../SectionTrackingDataContextProvider';
import * as styles from './SectionLayout.styles';

export interface SectionLayoutProps {
  children: ReactNode;
  extraCss?: SerializedStyles;
  horizontal?: boolean;
  paddingBottom?: boolean;
  link?: string | null;
  sectionHeadChildren?: ReactNode;
  title: string;
  titleLogo?: ReactNode;
  onLinkClick?: () => void;
  threshold?: 0 | 0.5;
}

interface SectionHeadContentProps extends Pick<SectionLayoutProps, 'link' | 'title' | 'onLinkClick' | 'titleLogo'> {
  children?: SectionLayoutProps['sectionHeadChildren'];
}

export const SectionHeadContent = memo(({ link, title, titleLogo, children, onLinkClick }: SectionHeadContentProps) => {
  const sectionTrackingData = useSectionTrackingDataContext();

  return (
    <div css={styles.sectionHeadContentStyle}>
      <div css={styles.sectionTitleWrapperStyle}>
        <h2 css={styles.sectionTitleStyle}>{title}</h2>
        {titleLogo}
      </div>
      <div css={styles.sectionHeadExtraStyle}>
        {children}
        {children && <div css={styles.sectionHeadExtraDividerStyle} />}
        {link && (
          <TrackClickEvent
            screenName={sectionTrackingData.screenName}
            target="section_header"
            params={sectionTrackingData.params}>
            <a css={styles.sectionMoreLinkStyle} href={link} onClick={() => onLinkClick?.()}>
              더보기
            </a>
          </TrackClickEvent>
        )}
      </div>
    </div>
  );
});

/**
 *
 * @param props.children section content
 * @param props.horizontal whether the section is horizontally scrolled or not
 * @param props.extraCss section extra layout style, not recommended
 * @param props.paddingBottom section padding bottom
 * @param props.link section link
 * @param props.title section title
 * @param props.sectionHeadChildren section head right side content
 * @returns {ReactJSX.Element}
 */

export const SectionLayout = ({
  children,
  extraCss,
  horizontal = true,
  paddingBottom = true,
  link,
  title,
  titleLogo,
  sectionHeadChildren,
  onLinkClick,
  threshold,
}: SectionLayoutProps): ReactJSX.Element => {
  const sectionLayoutStyle = [
    horizontal ? styles.horizontalSectionStyle : styles.sectionStyle,
    styles.sectionMarginStyle,
    paddingBottom && styles.extraPaddingBottomStyle,
    extraCss,
  ];
  const sectionHeaderStyle = [styles.sectionHeaderStyle, horizontal && styles.horizontalSectionHeaderStyle];
  const sectionTrackingData = useSectionTrackingDataContext();

  return (
    <TrackViewEvent
      screenName={sectionTrackingData.screenName}
      target="section"
      params={sectionTrackingData.params}
      threshold={threshold}>
      <section css={sectionLayoutStyle}>
        {!!title && (
          <div css={sectionHeaderStyle}>
            <SectionHeadContent link={link} title={title} titleLogo={titleLogo} onLinkClick={onLinkClick}>
              {sectionHeadChildren}
            </SectionHeadContent>
          </div>
        )}
        {children}
      </section>
    </TrackViewEvent>
  );
};
