import { createContext } from 'react';

export type GenreHomeContextType = {
  seed: string;
};

export const GenreHomeContext = createContext<GenreHomeContextType>({
  seed: '',
});

export const GenreHomeContextProvider = GenreHomeContext.Provider;
