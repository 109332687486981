export const DESKTOP_BOOK_DEFAULT_COVER_WIDTH = 180;
export const DESKTOP_BOOK_DEFAULT_COVER_HEIGHT = 261;
export const MOBILE_BOOK_DEFAULT_COVER_WIDTH = 130;
export const MOBILE_BOOK_DEFAULT_COVER_HEIGHT = 188;

export const DESKTOP_BOOK_3INFO_BOOK_WIDTH = DESKTOP_BOOK_DEFAULT_COVER_WIDTH;
export const DESKTOP_BOOK_3INFO_BOOK_HEIGHT = 355;
export const MOBILE_BOOK_3INFO_BOOK_WIDTH = MOBILE_BOOK_DEFAULT_COVER_WIDTH;
export const MOBILE_BOOK_3INFO_BOOK_HEIGHT = 263;

export const DESKTOP_BOOK_2INFO_BOOK_WIDTH = DESKTOP_BOOK_DEFAULT_COVER_WIDTH;
export const DESKTOP_BOOK_2INFO_BOOK_HEIGHT = 332;
export const MOBILE_BOOK_2INFO_BOOK_WIDTH = MOBILE_BOOK_DEFAULT_COVER_WIDTH;
export const MOBILE_BOOK_2INFO_BOOK_HEIGHT = 245;
