import { RefObject, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { currentPathSelector } from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { getStorageItemJSON, SESSION_STORAGE_KEYS, setStorageItemJSON } from '@/utils/storage';

const STORAGE_TYPE = 'session';

const getSubKeyByGenre = (currentNavigationPath: string | null) =>
  currentNavigationPath?.split('/')?.at(1) ?? 'unknown';

const saveScrollPosition = (element: HTMLElement | null, subKey: string) => {
  if (element) {
    const previousSavedScrollPositionObj =
      getStorageItemJSON(STORAGE_TYPE, SESSION_STORAGE_KEYS.SCROLL_POSITION_TAB_ITEMS_AB) || {};
    setStorageItemJSON(STORAGE_TYPE, SESSION_STORAGE_KEYS.SCROLL_POSITION_TAB_ITEMS_AB, {
      ...previousSavedScrollPositionObj,
      [subKey]: element.scrollLeft.toString(),
    });
  }
};

export const useNavigationScrollPosition = (ref: RefObject<HTMLElement>) => {
  const [isReady, setIsReady] = useState(false); // 보여줄 준비 여부 상태
  const currentNavigationPath = useSelector(currentPathSelector);
  const subKey = getSubKeyByGenre(currentNavigationPath);

  // 스크롤 위치 복원
  useLayoutEffect(() => {
    const savedScrollPositionObj = getStorageItemJSON(STORAGE_TYPE, SESSION_STORAGE_KEYS.SCROLL_POSITION_TAB_ITEMS_AB);
    const currentElement = ref.current;
    if (currentElement && savedScrollPositionObj && savedScrollPositionObj[subKey]) {
      currentElement.scrollLeft = parseFloat(savedScrollPositionObj[subKey]);
    }
    setIsReady(true); // 스크롤 위치 복원이 끝난 후 보여줄 준비 완료
  }, [ref, subKey]);

  // 스크롤 위치 저장
  useEffect(() => {
    const handleScroll = () => saveScrollPosition(ref.current, subKey);

    const currentElement = ref.current;
    if (currentElement) {
      currentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentElement) {
        currentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref, subKey]);

  return isReady; // 컴포넌트가 보여줄 준비가 되었는지 반환
};
