import { css } from '@emotion/react';

import { displayNoneForTouchDevice, scrollBarHidden } from '@/components/styles';

export const scrollContainerWrapperStyle = css`
  position: relative;
`;

export const slidingWrapperStyle = css`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  ${scrollBarHidden};
`;

export const markerStyle = css`
  flex: none;
  width: 1px;
`;

export const markerStartStyle = css`
  margin-right: -1px;
`;

export const markerEndStyle = css`
  margin-left: -1px;
`;

export const contentStyle = css`
  display: flex;
  flex: 1 0 auto;
`;

export const sliderWrapperStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  ${displayNoneForTouchDevice};
`;
