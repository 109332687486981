import { css, SerializedStyles } from '@emotion/react';

import { pageContainerStyle } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';
import type { RIDITheme } from '@/components/styles/themes';

import { sectionSkeletonBaseStyle } from '../../sections/index.styles';

export const sectionLayoutSkeletonContainerStyle = css`
  ${pageContainerStyle};
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-top: 40px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-bottom: 16px;
    `,
  )}
`;

export const sectionHeaderSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 166px;
  height: 26px;
  margin: 6px 0 16px;

  ${sectionSkeletonBaseStyle(theme)};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 100px;
      height: 20px;
      margin: 6px 0 11px;
    `,
  )}
`;
