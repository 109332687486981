import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const listContainerStyle = css`
  display: flex;
`;

export const itemContainerStyle = css`
  width: calc((100% - 12px) / 3);
  height: 100%;
  margin-right: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-of-type {
    margin-right: 0;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 100%;
      margin: 0px;
      &:not(:first-of-type) {
        display: none;
      }
    `,
  )}
`;

export const imageSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 100%;
  height: 140px;
  margin-bottom: 20px;

  background-color: ${theme.colors.grey100};

  border-radius: 4px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-bottom: 16px;
    `,
  )}
`;

export const sentenseSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 220px;
  height: 19px;
  margin-bottom: 6px;

  background-color: ${theme.colors.grey100};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 160px;
      height: 17px;
      margin-bottom: 2px;
    `,
  )}
`;

export const titleSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 100px;
  height: 16px;

  background-color: ${theme.colors.grey100};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 70px;
      height: 14px;
    `,
  )}
`;
