import { css, SerializedStyles } from '@emotion/react';
import { scrollBarHidden } from '@ridi-web/design-system/Styles';
import { rgba } from '@ridi-web/design-system/Utils';

import { pageContainerStyle } from '@/components/styles';
import { BreakPoint, nonTouchDevice, orBelow } from '@/components/styles/media';
import { a11y } from '@/components/styles/reset';
import { RIDITheme } from '@/components/styles/themes';

export const wrapperStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.bgBase};
  position: relative;
`;

export const tabWrapperStyle = css`
  ${pageContainerStyle};
  padding-left: 0;
  padding-right: 0;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-left: 0;
      padding-right: 0;
    `,
  )};
`;

export const scrollWrapperStyle = css`
  display: block;
`;

export const defaultItemsStyle = css`
  display: flex;
  ${scrollBarHidden};

  &[aria-hidden='true'] {
    visibility: hidden;
  }

  &[aria-hidden='false'] {
    visibility: visible;
  }
`;

export const defaultItemContainerStyle = css`
  display: inline-flex;
  flex: 0 0 auto;
`;

export const defaultItemContainer2Style = css`
  display: flex;
  align-items: center;
`;

export const tabDividerStyle = (theme: RIDITheme): SerializedStyles => css`
  height: 1px;
  background: ${theme.colors.grey100};

  border: none;
  margin: 0 30px;
  margin-top: -1px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-left: 0;
      margin-right: 0;
    `,
  )};
`;

export const tabStyle = css`
  padding: 0 18px;
  padding-right: 30px;
  overflow: auto;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 8px;
    `,
  )};
`;

export const tabItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0 12px;
  padding-top: 14px;
  padding-bottom: 17px;

  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillPrimary};
  transition: opacity 0.2s ease;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 8px;
      padding-top: 12px;
      padding-bottom: 13px;

      font-size: 16px;
      line-height: 19px;
    `,
  )};

  &:hover {
    opacity: 0.6;
  }
`;

export const tabItemActiveStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.blue};
  cursor: default;
  pointer-events: none;
  touch-action: none;

  &:hover {
    opacity: 1;
  }
`;

export const allCategoriesStyle = css`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
`;

export const allCategoriesLinkStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  align-items: center;
  color: ${theme.colors.fillPrimary};
  padding: 12px 8px;
  padding-right: 1px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 8px;
      padding-right: 8px;
    `,
  )};
`;

export const allCategoriesIconStyle = css`
  font-size: 26px;
  margin-right: 6px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 24px;
      margin-right: 0;
    `,
  )};
`;

export const cashStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: none;
    `,
  )};
`;

export const allCategoriesTextStyle = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.02em;

  ${orBelow(BreakPoint.DesktopSmall, a11y)};
`;

export const subTabStyle = css`
  padding: 7px 28px;
  overflow: auto;
  display: flex;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 12px;
      padding-bottom: 10px;
      padding-top: 8px;
    `,
  )};
`;

export const subTab2Style = css`
  display: flex;
  gap: 2px;
  padding: 13px 30px;
  overflow-x: auto;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      gap: 0;
      padding: 11px 14px;
    `,
  )};
`;

export const subTab6Style = css`
  white-space: nowrap;
`;

export const subTab3Style = css`
  display: flex;
  gap: 2px;
  padding: 13px 30px;
  overflow-x: auto;
  white-space: nowrap;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      gap: 0;
      padding: 11px 14px;
    `,
  )};
`;

export const subTab4Style = css`
  display: flex;
  padding: 10px 18px 0;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 9px 9px 0;
    `,
  )};
`;

export const subTab5Style = css`
  display: flex;
  gap: 2px;
  padding: 12px 30px 14px;
  overflow-x: auto;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      gap: 0;
      padding: 11px 14px;
    `,
  )};
`;

export const subTabItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 34px;
  border-radius: 40px;
  margin: 6px 2px;
  padding: 0 10px;

  color: ${theme.colors.fillPrimary};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.01em;

  transition: all 0.2s ease-in-out;

  ${nonTouchDevice(css`
    &:hover {
      opacity: 0.4;
    }
  `)}

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 30px;
      margin: 0;
      font-size: 14px;
      line-height: 17px;
    `,
  )};
`;

export const subTabItem2Style = (theme: RIDITheme): SerializedStyles => css`
  display: inline-block;
  padding: 7px 10px 8px;

  border-radius: 99px;
  color: ${theme.colors.fillPrimary};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.01em;

  transition: all 0.2s ease-in-out;

  ${nonTouchDevice(css`
    &:hover {
      opacity: 0.4;
    }
  `)}
  &[aria-selected='true'] {
    background: ${theme.colors.blue};
    color: ${theme.colors.white};
    cursor: default;
    font-weight: 700;
    pointer-events: none;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      opacity: 1;
    }
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 7px 10px 6px;
      font-size: 14px;
      line-height: 17px;
    `,
  )};
`;

export const subTabItem3Style = (theme: RIDITheme): SerializedStyles => css`
  display: inline-block;
  padding: 12px;

  color: ${theme.colors.fillPrimary};
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.01em;

  transition: all 0.2s ease-in-out;

  ${nonTouchDevice(css`
    &:hover {
      opacity: 0.4;
    }
  `)}

  &[aria-selected='true'] {
    color: ${theme.colors.blue};
    cursor: default;
    pointer-events: none;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      opacity: 1;
    }
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 6px 9px;
      font-size: 18px;
      line-height: 19px;
    `,
  )};
`;

export const subTabItemActiveStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.blue};
  color: ${theme.colors.white};
  font-weight: 700;
  cursor: default;
  pointer-events: none;
  touch-action: none;

  &:hover {
    opacity: 1;
  }
`;

export const subTabContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const buttonsStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  position: relative;
  margin: 14px 30px 14px 0;
  padding: 2px;
  text-align: center;

  border-radius: 8px;
  background: ${theme.colors.grey300};
  color: ${theme.colors.fillSecondary};
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin: 0 16px 0 0;
      padding: 3px;
      background: ${theme.colors.grey200};
      border-radius: 6px;
      font-size: 13px;
      line-height: 13px;
    `,
  )};
`;

const BUTTON_WIDTH = {
  MOBILE: 52,
  TABLET: 76,
  DESKTOP: 100,
};

export const buttonStyle = (theme: RIDITheme): SerializedStyles => css`
  position: relative;
  z-index: 1;
  width: ${BUTTON_WIDTH.DESKTOP}px;
  padding: 7px;

  border-radius: 6px;
  font-weight: 600;
  transition: color 0.3s ease;

  &[aria-selected='true'] {
    color: ${theme.colors.black};
    pointer-events: none;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: block;
      width: ${BUTTON_WIDTH.TABLET}px;
      padding: 4px 9px;
    `,
  )};

  ${orBelow(
    600,
    css`
      display: block;
      width: ${BUTTON_WIDTH.MOBILE}px;
      padding: 4px 9px;
    `,
  )}
`;

export const sliderStyle = (theme: RIDITheme): SerializedStyles => css`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: ${BUTTON_WIDTH.DESKTOP}px;
  margin: 2px 0;

  background: ${theme.colors.white};
  border-radius: 6px;
  transition: transform 0.3s ease;
  box-shadow: 0 0 6px 0 ${rgba(theme.colors.black, 0.04)};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: ${BUTTON_WIDTH.TABLET}px;
      margin: 3px 0;
      border-radius: 3px;
    `,
  )};

  ${orBelow(
    600,
    css`
      width: ${BUTTON_WIDTH.MOBILE}px;
      margin: 3px 0;
      border-radius: 3px;
    `,
  )}
`;

export const buttonsBgStyles = (theme: RIDITheme): SerializedStyles => css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      &[aria-hidden='false'] {
        position: absolute;
        top: -5px;
        left: -20px;
        width: 20px;
        height: 40px;

        background: linear-gradient(
          270deg,
          ${theme.colors.bgBase}FF 0%,
          ${theme.colors.bgBase}FF 50%,
          ${theme.colors.bgBase}00 100%
        );

        pointer-events: none;
        touch-action: none;
      }
    `,
  )};
`;

export const buttonsBg2Styles = (theme: RIDITheme): SerializedStyles => css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      &[aria-hidden='false'] {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;

        background: linear-gradient(
          270deg,
          ${rgba(theme.colors.bgBase, 1)} 0,
          ${rgba(theme.colors.bgBase, 1)} 10px,
          ${rgba(theme.colors.bgBase, 0)} 100%
        );

        pointer-events: none;
        touch-action: none;
      }
    `,
  )};
`;

export const wrapperStyle2 = css`
  overflow: auto;
`;
