import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const wrapperSkeletonStyle = css`
  max-width: 1170px;
  padding: 0 30px;
  margin: 0 auto 16px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 16px;
      margin: 0 auto 16px;
    `,
  )}
`;

export const listSkeletonStyle = css`
  display: flex;
  height: 400px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      min-width: 375px;
      height: 343px;
    `,
  )}
`;

export const mainContentSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 100%;
  max-width: 1018px;
  height: 100%;
  border-radius: 6px;

  background-color: ${theme.colors.grey100};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 343px;
    `,
  )}
`;

export const subContentSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 40px;
  margin-left: 6px;
  border-radius: 6px;

  background-color: ${theme.colors.grey100};
`;
