import * as styles from './QuickMenuSkeleton.styles';

const quickMenuSkeletonItems = Array.from({ length: 8 }, (_, index) => index);

export const QuickMenuSkeleton = (): ReactJSX.Element => (
  <section css={styles.wrapperStyle}>
    {quickMenuSkeletonItems.map(v => (
      <div key={v} css={styles.itemWrapperStyle}>
        <div css={styles.iconSkeletonStyle} />
        <div css={styles.labelSkeletonStyle} />
      </div>
    ))}
  </section>
);
