import { SectionLayoutSkeleton } from '../SectionLayout';
import * as styles from './ListLayoutWithColorBoxSkeleton.styles';

const listLayoutWithColorBoxSkeletonItems = Array.from({ length: 3 }, (_, index) => index);

export const ListLayoutWithColorBoxSkeleton = (): ReactJSX.Element => (
  <SectionLayoutSkeleton>
    <ul css={styles.listContainerStyle}>
      {listLayoutWithColorBoxSkeletonItems.map(v => (
        <li key={v} css={styles.itemContainerStyle}>
          <div css={styles.imageSkeletonStyle} />
          <div css={styles.sentenseSkeletonStyle} />
          <div css={styles.sentenseSkeletonStyle} />
          <div css={styles.titleSkeletonStyle} />
        </li>
      ))}
    </ul>
  </SectionLayoutSkeleton>
);
