import { Renewal2InfoBookSkeleton, Renewal3InfoBookSkeleton } from '@/components/common/Book';

import { SectionLayoutSkeleton } from '../SectionLayout';
import * as styles from './ListLayoutSectionSkeleton.styles';

const listLayoutSectionSkeletonItems = Array.from({ length: 6 }, (_, index) => index);

interface ListLayoutSectionItemsSkeletonProps {
  className?: string;
}

export const ListLayoutSection3InfoItemsSkeleton = ({
  className,
}: ListLayoutSectionItemsSkeletonProps): ReactJSX.Element => (
  <ul css={styles.listLayoutContainerSkeletonStyle} className={className}>
    {listLayoutSectionSkeletonItems.map(value => (
      <Renewal3InfoBookSkeleton key={value} css={styles.listLayoutItemContainerSkeletonStyle} />
    ))}
  </ul>
);

export const ListLayoutSection3InfoSkeleton = (): ReactJSX.Element => (
  <SectionLayoutSkeleton>
    <ListLayoutSection3InfoItemsSkeleton />
  </SectionLayoutSkeleton>
);

export const ListLayoutSection2InfoItemsSkeleton = ({
  className,
}: ListLayoutSectionItemsSkeletonProps): ReactJSX.Element => (
  <ul css={styles.listLayoutContainerSkeletonStyle} className={className}>
    {listLayoutSectionSkeletonItems.map(value => (
      <Renewal2InfoBookSkeleton key={value} css={styles.listLayoutItemContainerSkeletonStyle} />
    ))}
  </ul>
);
export const ListLayoutSection2InfoSkeleton = (): ReactJSX.Element => (
  <SectionLayoutSkeleton>
    <ListLayoutSection2InfoItemsSkeleton />
  </SectionLayoutSkeleton>
);
