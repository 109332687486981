import { css, SerializedStyles } from '@emotion/react';

import { ZIndexLayer } from '@/components/styles';
import { BreakPoint, orBelow, touchDevice } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';
import { rgba } from '@/utils/rgba';

const topButtonHideStyle = css`
  &[data-is-hidden='true'] {
    opacity: 0;
    pointer-events: none;
    transform: translate(0, calc(100% + 50px));
  }
`;

export const topButtonStyle = (theme: RIDITheme): SerializedStyles => css`
  position: fixed;
  width: 58px;
  height: 58px;
  bottom: 30px;
  right: 0;
  z-index: ${ZIndexLayer.LEVEL_HEADER};

  margin-left: auto;
  margin-right: 30px;

  color: ${theme.colors.fillPrimary};
  font-size: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: ${rgba(theme.colors.bgBase, 0.9)};
  border: 1px solid ${theme.colors.grey300};

  cursor: pointer;
  outline: none;
  border-radius: 29px;

  opacity: 1;
  pointer-events: auto;
  transition:
    opacity 0.4s ease,
    pointer-events 0.4s ease,
    transform 0.4s ease;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      position: sticky;
      right: unset;

      width: 44px;
      height: 44px;
      bottom: 32px;

      /* Footer padding 50px + Button 44px */
      margin-top: -94px;
      margin-right: 16px;
      font-size: 18px;
    `,
  )};

  ${orBelow(
    BreakPoint.DesktopMedium,
    css`
      ${topButtonHideStyle};
    `,
  )};
  ${touchDevice(topButtonHideStyle)};
`;
