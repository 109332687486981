import { SectionLayoutSkeleton } from '../../common/SectionLayout';
import * as styles from './SelectionCoverSkeleton.styles';

const selectionCoverSkeletonItems = Array.from({ length: 5 }, (_, index) => index);

export const SelectionCoverSkeleton = (): ReactJSX.Element => (
  <SectionLayoutSkeleton>
    <ul css={styles.wrapperStyle}>
      {selectionCoverSkeletonItems.map(v => (
        <li key={v} css={styles.itemStyle}>
          <div css={styles.coverSkeletonStyle} />
        </li>
      ))}
    </ul>
  </SectionLayoutSkeleton>
);
