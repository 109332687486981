import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const wrapperStyle = css`
  display: flex;
`;

export const itemStyle = css`
  &:not(:last-of-type) {
    margin-right: 6px;
  }
`;

export const eventSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 366px;
  height: 90px;

  background-color: ${theme.colors.grey100};
  border-radius: 4px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 343px;
    `,
  )}
`;
