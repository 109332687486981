import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import type { RIDITheme } from '@/components/styles/themes';

import { sectionSkeletonBaseStyle } from '../../sections/index.styles';

export const THUMBNAIL_DESKTOP_WIDTH = 80;
export const THUMBNAIL_DESKTOP_HEIGHT = 116;

export const THUMBNAIL_MOBILE_WIDTH = 70;
export const THUMBNAIL_MOBILE_HEIGHT = 101;

const RANKING_CONTAINER_DESKTOP_WIDTH = 80;
const RANKING_CONTAINER_MOBILE_WIDTH = 51;

const METADATA_CONTAINER_DESKTOP_WIDTH = 184;
const METADATA_CONTAINER_MOBILE_WIDTH = 181;

const METADATA_CONTAINER_FIXED_RIGHT_MARGIN = 26;

export const containerStyle = (theme: RIDITheme): SerializedStyles => css`
  position: relative;
  display: flex;
  height: 100%;
  background-color: ${theme.colors.bgBase};
`;

export const thumbnailContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  width: ${THUMBNAIL_DESKTOP_WIDTH}px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: ${THUMBNAIL_MOBILE_WIDTH}px;
    `,
  )};
`;

export const rankingContainerStyle = css`
  width: ${RANKING_CONTAINER_DESKTOP_WIDTH}px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: ${RANKING_CONTAINER_MOBILE_WIDTH}px;
    `,
  )};
`;

export const rankingStyle = (theme: RIDITheme): SerializedStyles => css`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillPrimary};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 18px;
      line-height: 21px;
    `,
  )}
`;

export const metadataContainerStyle = css`
  width: ${METADATA_CONTAINER_DESKTOP_WIDTH}px;
  height: 100%;
  margin-right: ${METADATA_CONTAINER_FIXED_RIGHT_MARGIN}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: ${METADATA_CONTAINER_MOBILE_WIDTH}px;
    `,
  )};
`;

export const authorStyle = css`
  display: block;
  white-space: nowrap;
  flex: 0 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const authorFreebookMarginStyle = css`
  margin-top: 6px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 4px;
    `,
  )}
`;

export const freebookStyle = css`
  flex: 0 0 auto;
`;

export const freebookUnitFontSizeStyle = css`
  font-size: 14px;
  line-height: 17px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 12px;
      line-height: 14px;
    `,
  )}
`;

export const ratingMarginStyle = css`
  margin-top: 7px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 5px;
    `,
  )}
`;

export const ratingStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 12px;
    `,
  )}
`;

export const bookContainerSkeletonStyle = css`
  display: flex;
  width: 370px;
  height: 116px;
  margin-bottom: 6px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 337px;
      height: 101px;
    `,
  )};

  &:nth-of-type(n + 7) {
    margin-bottom: 0px;
  }
`;

export const bookThumbnailSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  ${sectionSkeletonBaseStyle(theme)};

  width: 80px;
  height: 100%;
  margin-right: 80px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 70px;
      height: 101px;
      margin-right: 60px;
      font-size: 18px;
    `,
  )}
`;

export const metaContainerSkeletonStyle = css`
  height: 100%;
  padding-top: 24px;
  padding-right: 60px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-top: 15px;
      padding-right: 87px;
    `,
  )}
`;

export const metaBookTitleSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  ${sectionSkeletonBaseStyle(theme)};

  width: 150px;
  height: 19px;
  margin-bottom: 6px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 120px;
      height: 16px;
      margin-bottom: 4px;
    `,
  )}
`;

export const metaBookAuthorSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  ${sectionSkeletonBaseStyle(theme)};

  width: 100px;
  height: 16px;
  margin-bottom: 12px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 72px;
      height: 16px;
      margin-bottom: 5px;
    `,
  )}
`;

export const metaBookRateSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  ${sectionSkeletonBaseStyle(theme)};

  width: 50px;
  height: 16px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 30px;
      height: 14px;
    `,
  )}
`;
