import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const wrapperStyle = css`
  max-width: 1170px;

  margin: 0 auto 30px;
  padding: 0 30px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0;
    `,
  )}
`;

export const listStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  width: 100%;
  height: 364px;

  padding-top: 53px;
  padding-left: 93px;

  background-color: ${theme.colors.grey300};
  border-radius: 4px;

  overflow: hidden;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 200px;

      padding-top: 37px;
      padding-left: 16px;
      margin-bottom: 27px;

      border-radius: 0;
      overflow: visible;
    `,
  )}
`;

export const itemStyle = css`
  margin-right: 6px;
`;

export const bookSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 180px;
  height: 261px;

  background-color: ${theme.colors.grey100};
  border-radius: 4px;

  z-index: 999;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 120px;
      height: 174px;
    `,
  )}
`;
