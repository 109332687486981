import type { HorizontalScrollContainerScrollBehavior } from './HorizontalScrollContainer';

export const defaultBehavior: HorizontalScrollContainerScrollBehavior = ({ container }, multiplier) => {
  container.scrollBy({
    top: 0,
    left: container.clientWidth * multiplier,
    behavior: 'smooth',
  });
};

export const circulateBehavior =
  (next = defaultBehavior): HorizontalScrollContainerScrollBehavior =>
  (context, multiplier) => {
    const { container, isOnStart, isOnEnd } = context;
    if (isOnStart && multiplier < 0) {
      container.scrollTo({
        top: 0,
        left: container.scrollWidth - container.clientWidth,
        behavior: 'smooth',
      });
      return;
    }

    if (isOnEnd && multiplier > 0) {
      container.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }

    next(context, multiplier);
  };

export const snapBehavior = (
  snapSize: number,
  option: { gutterSize?: number; offsetSize?: number } = {},
  next = defaultBehavior,
): HorizontalScrollContainerScrollBehavior => {
  const { gutterSize = 0, offsetSize = 0 } = option;

  const snapBoxSize = snapSize + gutterSize;

  /*
   * ## Offset의 동작방식
   *
   * <--offset--><----------snapBox---------><----------snapBox---------><----------snapBox---------><--offset-->
   * ~~~~~~~~~~~~~~~~~~~~~~~~~scroll right~~~~~~~~~~~~~~~~~~~~~~~~~~>
   *
   *                                                         <~~~x~~~    // 2 * snapbox 로 스냅하지 않음 (마지막 snapBox는 짤렸기 때문에)
   * <----------snapBox---------><----------snapBox---------><--offset-->
   *
   *                             <~~~~~~~~~~~~~~~~~o~~~~~~~~~~~~~~~~~    // 대신 1 * snapbox 로 스냅함
   * <----------snapBox---------><--offset-->
   */

  /*
   * ## Gutter의 동작방식
   * <----------snapBox---------><----------snapBox---------><----------snapBox--------->
   * <------item------><-gutter-><------item------><-gutter-><------item------><-gutter->
   *
   * ~~~~~~~~~~~~~~~~~~~scroll right~~~~~~~~~~~~~~~~~~~>
   *                             <~~~~~~~~~~~x~~~~~~~~~~       // 1 * snapbox로 스냅하지 않음 (이미 item 2개는 다 보여줬기 때문)
   *                                                   ~~o~~> // 대신 2 * snapbox로 스냅함
   */

  // 따라서 gutter는 + 방향으로 보정, offset은 - 방향으로 보정함
  const scrollingOffset = gutterSize - offsetSize;

  return (context, multiplier) => {
    const { container } = context;
    const { scrollLeft, clientWidth } = container;
    const nextScroll = scrollLeft + multiplier * clientWidth;

    let targetScroll: number;
    if (multiplier < 0) {
      /*
       * ## 왼쪽 스크롤의 동작방식
       * |<---snapBox--->|<---snapBox--->|<---snapBox--->|<---snapBox--->|<---snapBox--->|<---snapBox--->|
       * |               |               |               |  <--------------screen------------->          |
       * |               <--------------screen-------------><~~~~~~~~~~~~scroll left~~~~~~~~~~~          |
       * |               |               |               |               |               |               |
       * |               |               |               |        ^ 이 snapBox가 잘려있었으니 보여줘야 함 (1)
       * |               ~~~~snap~~~~~><-------------screen------------->|               |               |
       * |               |             ^ 왼쪽 정렬을 맞춰줘야 함 (2)     |               |               |
       * |               |             ~~><-------------screen------------->             |               |
       */

      /*
       * ## 왼쪽 스크롤 + Offset의 동작방식
       * |<offset>|<------A------>|<------B------>|<------C------>|<------D------>|<------E------>|<------F------>|<offset>
       * |                                              <--------------screen------------>
       * |                  <-----------screen---------><~~~~~~~~~~~~~scroll left~~~~~~~~~
       * |                                               ^ 오프셋 적용된 시점에서 C를 다시보여줘야 함 (1)
       * |
       * |                  x<-----------screen--------->                              // 우측을 C'로 스냅하지 않음 (C롤 보여줘야 해서)
       * |               |<------B'----->|<------C'----->|<offset>|
       * |
       * |                  ~~~~~~~~o~~~~~~~><-----------screen--------->              // 우측을 D'로 스냅함
       * |               |<------B'----->|<------C'----->|<------D'----->|
       * |                                       ^ 왼쪽 정렬을 맞춰줘야 함 (2)
       * |
       * |                               |<offset>|<------C------>|
       * |                                               |<------D'----->|
       * |                                   ~~~~~x~~~~~><-----------screen---------> // 좌측을 D'로 스냅하는 순간 기존보다 더 우측으로 가는 것이 된다!
       * |
       * |                               |<offset>|<------C------>|
       * |                               |<------C'----->|<------D'----->|
       * |                                <-----------screen---------><~               // 좌측을 C'로 스냅해야 올바르게 된다
       */

      // 따라서 (1)에서 offset이 + 방향으로 보정되어야 하고, Gutter는 반대로 -로 보정되어야 한다.
      // 따라서 (2)에서 offset이 - 방향으로 보정되어야 한다, Gutter는 반대로 +로 보정되어야 한다.

      // (1)
      const nextScrollRight = Math.ceil((nextScroll + clientWidth - scrollingOffset) / snapBoxSize) * snapBoxSize;
      targetScroll = nextScrollRight - clientWidth;

      // (2)
      targetScroll = Math.ceil((targetScroll + scrollingOffset) / snapBoxSize) * snapBoxSize;
    } else {
      targetScroll = Math.floor((nextScroll + scrollingOffset) / snapBoxSize) * snapBoxSize;
    }

    next(context, (targetScroll - scrollLeft) / clientWidth);
  };
};
