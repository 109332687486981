import styled from '@emotion/styled';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

import { IconArrow } from '@/assets/svgs/system';
import { a11y } from '@/components/styles/reset';

import * as styles from './ArrowButton.styles';

export const ArrowButtonIcon = styled(IconArrow, { shouldForwardProp: prop => prop !== 'direction' })<{
  direction: 'left' | 'right';
}>`
  ${styles.arrowButtonIconStyle};
  ${({ direction }) => direction === 'left' && styles.arrowButtonIconLeftStyle};
`;

type ArrowButtonProps = {
  direction: 'left' | 'right';
  label: string;
  icon?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ArrowButton = forwardRef<HTMLButtonElement, ArrowButtonProps>(
  ({ direction, label, icon, ...buttonProps }, ref): ReactJSX.Element => (
    <button css={styles.arrowButtonWrapperStyle} type="button" ref={ref} {...buttonProps}>
      {icon || <ArrowButtonIcon size={16} thick={1.4} direction={direction} />}
      <span css={a11y}>{label}</span>
    </button>
  ),
);
