import { css, SerializedStyles } from '@emotion/react';

import { clearOutline } from '@/components/styles/reset';
import { RIDITheme } from '@/components/styles/themes';
import { rgba } from '@/utils/rgba';

export const arrowButtonWrapperStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  cursor: pointer;
  background: ${theme.colors.bgTabBar};
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  border: 1px solid ${theme.colors.grey200};
  pointer-events: auto;
  transition:
    opacity 0.2s ease,
    color 0.2s ease,
    pointer-events 0.2s ease;

  color: ${theme.colors.fillPrimary};
  font-size: 16px;

  ${clearOutline};
  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    color: ${rgba(theme.colors.fillPrimary, 0.4)};
  }
`;

export const arrowButtonIconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const arrowButtonIconLeftStyle = css`
  transform: rotate(180deg);
`;
