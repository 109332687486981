import { css } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';

export const listLayoutContainerSkeletonStyle = css`
  display: flex;
  padding-bottom: 20px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-bottom: 16px;
    `,
  )}
`;

export const listLayoutItemContainerSkeletonStyle = css`
  margin-right: 6px;

  &:last-of-type {
    margin: 0px;
  }
`;
